* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'montseratt', sans-serif;
}

h1 {
  text-align: center;
  font-size: 20px; 
  color: rgba(50, 50, 70, 0.5);
}

#popover-basic {
  margin-top: 10px; 
}

#popover-basic::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;    
    border-width: 10px;
    border-style: solid; 
    border-color: transparent transparent white transparent;
}

.app {
  background-image: url('./assets/cold-img.jpg');
  background-size: cover;
  background-position: center;
  transition: 0.4 ease-out;
}

.app.warm {
  background-image: url('./assets/warm-img.jpg');
}

main {
  min-height: 100vh;
  padding: 25px;
}

.search-box {
  width: 100%;
  margin: 0 0 55px;
}

.search-box .search-bar{
  display: block;
  width: 100%;
  padding: 15px;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px 16px 16px 16px;
  margin: 15px auto 10px auto;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  color: #313131;
  font-size: 16px;
  transition: 0.4s ease;
}

.search-box .search-bar:focus {
  background-color: rgba(255, 255, 255, 0.75);
}

.location-box .location {
  color: #FFF;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}

.location-box .date {
  padding-top: 10px;
  color: #FFF;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  text-shadow: 2px 2px 1px rgba(50, 50, 70, 0.5);
}

.weather-box {
  text-align: center;
  padding-bottom: 10px;
}

.weather-box .temp {
  position: relative;
  display: inline-block;
  margin: 30px auto 20px auto;
  padding-top: 30px!important;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 15px 25px;
  color: #FFF;
  font-size: 102px;
  font-weight: 900;
  text-shadow: 1px 6px rgba(50, 50, 70, 0.5);
  text-align: center;
  box-shadow: 3px 6px rgba(0, 0, 0, 0.2);
}

.weather-box .weather {
  color: #FFF;
  font-size: 48px;
  font-weight: 700;
  text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}

.wind, .humidity, .feels-like, .pressure, .sunrise, .sunset, .cloud {
  color: #FFF;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-shadow: 3px 2px 1px rgba(50, 50, 70, 0.5);
}

@media (max-width: 414px) {
  h1 {
    font-size: 20px!important;
  }
  .search-box {
    margin: 0 0 10px;
  }
  .search-box .searchbar {
    font-size: 18px;
    padding: 10px;
  }
  .weather-box .temp {
    font-size: 50px;
    margin: 15px auto 10px auto;
  }
  .weather-box .weather {
    font-size: 22px;
  }
  .location-box .location {
    font-size: 28px; 
  }
  .location-box .date {
    font-size: 16px; 
  }
}